<template>
  <el-select
    v-model="syncModel"
    :multiple="multiple"
    no-data-text="暫無數據"
    placeholder="選擇月份"
    clearable
    @change="$emit('change')"
    @clear="$emit('clear')"
  >
    <el-option
      v-for="(item, index) in 12"
      :key="`month-${index}`"
      :label="`${item} 月`"
      :value="item"
    />
  </el-select>
</template>

<script>
export default {
  name: 'MonthSelect',
  props: ['model', 'multiple'],
  computed: {
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },
}
</script>
