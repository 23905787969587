import { ref } from 'vue'
import ls from 'local-storage'
import { map } from 'lodash'

export const useTableFilter = (tableName, filterOptionsConfig) => {
  const tableFilter = ref([])
  const tableRefresh = ref(false)

  const showColumn = (column) => {
    return tableFilter.value.includes(column)
  }

  const updateTableFilterConfig = (table, checked) => {
    const tableFilterConfig = ls.get('tableFilterConfig') ?? {}
    // if (!tableFilterConfig[table]) tableFilterConfig[table] = []
    tableFilterConfig[table] = checked
    ls.set('tableFilterConfig', tableFilterConfig)
  }

  const getTableFilterConfig = () => {
    const tableFilterConfig = ls.get('tableFilterConfig') ?? {}
    if (!tableFilterConfig[tableName]) {
      tableFilterConfig[tableName] = map(filterOptionsConfig, 'value')
      ls.set('tableFilterConfig', tableFilterConfig)
    }
    return tableFilterConfig[tableName]
  }

  const updateTableFilter = () => {
    tableRefresh.value = true
    tableFilter.value = getTableFilterConfig()
    setTimeout(() => {
      tableRefresh.value = false
    }, 0)
  }

  return {
    tableRefresh,
    showColumn,
    updateTableFilterConfig,
    updateTableFilter,
    getTableFilterConfig,
    tableFilter,
  }
}
